import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, Paper } from '@material-ui/core';
import AlbLoading from 'components/AlbLoading';
import CausalChainJustificationModal from 'components/AlembicModalConfirm/CausalChainJustificationModal';
import CausalAccordion from './CausalAccordion';
import Feedback from './Feedback';
import CausalHeader from './CausalHeader';

const useStyles = makeStyles({
  box: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    borderRadius: '10px',
    padding: '36px',
    marginBottom: '48px'
  },
  noResults: {
    position: 'relative',
    width: '100%',
    textAlign: 'center',
    fontSize: '20px',
    fontWeight: 500,
    color: '#0A1734',
    margin: '12px 0px'
  },
  accordionContainer: {
    opacity: 1,
    width: '100%',
    margin: '30px 0 0 0'
  },
  markdown: {
    fontFamily: "'Poppins', sans-serif",
    color: 'rgb(13, 26, 58)',

    '& h1': {
      fontSize: '28px',
      fontWeight: 500
    },
    '& h2': {
      fontSize: '24px',
      fontWeight: 500
    },
    '& h3': {
      fontSize: '18px',
      fontWeight: 500
    },
    '& ol, ul, p, li': {
      fontSize: '16px',
      fontWeight: 400
    }
  },
  loadingOverlay: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    zIndex: 1
  }
});

/**
 * @description For rendering the body of the Causal page
 * @param {Object} props - The component props
 * @param {array} props.justifyData - The justified data
 * @param {array} props.terminatingEvents - The terminating events
 * @param {array} props.selectedEventTypes - The selected event types
 * @param {function} props.setSelectedEventTypes - The function to set the selected event types
 * @param {boolean} props.loading - The loading state
 * @param {function} props.refetch - The function to refetch the data
 * @param {string} props.sort - The current sort value
 * @param {function} props.setSort - The function to set the sort value
 * @param {function} props.useSearchCausalChains - The function to search causal chains
 * @param {string} props.searchInputValue - The current search input value
 * @param {function} props.setSearchInputValue - The function to set the search input value
 * @returns {JSX.Element} The InsightsSummary component
 */
const CausalChainsList = props => {
  const {
    justifyData,
    useSearchCausalChains,
    terminatingEvents,
    selectedEventTypes,
    setSelectedEventTypes,
    loading,
    refetch,
    sort,
    setSort,
    searchInputValue,
    setSearchInputValue
  } = props;
  const classes = useStyles();

  // eslint-disable-next-line no-unused-vars
  const [visibleJustification, setVisibleJustification] = useState(false);

  const [isJustificationModalOpen, toggleJustificationModal] = useState(false);
  const [selectedCausalChain, setSelectedCausalChain] = useState(null);

  useEffect(() => {
    // handle re(fetching) the selected causal chain when feedback is provided in the modal
    if (isJustificationModalOpen) {
      const tempSelectedCausalChain = justifyData.find(item => item.id === selectedCausalChain.id);

      setSelectedCausalChain(tempSelectedCausalChain);
    }
    setVisibleJustification(true);
  }, [justifyData]);

  const expandJustify = (e, causalChain) => {
    setSelectedCausalChain(causalChain);
    toggleJustificationModal(true);
    e.stopPropagation();
  };

  return (
    <Grid container direction="column">
      <Paper className={`${classes.box}`}>
        {loading && (
          <Box className={classes.loadingOverlay}>
            <AlbLoading />
          </Box>
        )}

        <CausalHeader
          terminatingEvents={terminatingEvents}
          selectedEventTypes={selectedEventTypes}
          setSelectedEventTypes={setSelectedEventTypes}
          sort={sort}
          setSort={setSort}
          searchInputValue={searchInputValue}
          setSearchInputValue={setSearchInputValue}
          useSearchCausalChains={useSearchCausalChains}
        />

        {!loading && justifyData?.length === 0 && visibleJustification && (
          <div className={classes.noResults}>
            There are no causal chains visible for the selected event types.
          </div>
        )}
        {justifyData?.length > 0 && visibleJustification && (
          <>
            <div className={`${classes.accordionContainer}`}>
              <CausalAccordion justifyData={justifyData} expandJustify={expandJustify} />
            </div>

            {selectedCausalChain?.id != null && isJustificationModalOpen && (
              <CausalChainJustificationModal
                title={selectedCausalChain.title}
                justification={selectedCausalChain.justification}
                endDate={selectedCausalChain.end_date}
                isModalOpen={isJustificationModalOpen}
                onChange={e => toggleJustificationModal(e.isModalOpen)}
                feedbackComponent={
                  <Feedback
                    data={{ id: selectedCausalChain.id, feedback: selectedCausalChain.feedback }}
                    refetch={refetch}
                  />
                }
              />
            )}
          </>
        )}
      </Paper>
    </Grid>
  );
};

CausalChainsList.propTypes = {
  justifyData: PropTypes.arrayOf(PropTypes.shape()),
  terminatingEvents: PropTypes.arrayOf(PropTypes.string),
  selectedEventTypes: PropTypes.arrayOf(PropTypes.string),
  setSelectedEventTypes: PropTypes.func,
  loading: PropTypes.bool,
  refetch: PropTypes.func,
  sort: PropTypes.string,
  setSort: PropTypes.func,
  useSearchCausalChains: PropTypes.func,
  searchInputValue: PropTypes.string,
  setSearchInputValue: PropTypes.func
};

CausalChainsList.defaultProps = {
  justifyData: null,
  terminatingEvents: [],
  selectedEventTypes: [],
  setSelectedEventTypes: () => {},
  loading: false,
  refetch: () => {},
  sort: null,
  setSort: () => {},
  useSearchCausalChains: () => {},
  searchInputValue: '',
  setSearchInputValue: () => {}
};

export default CausalChainsList;
