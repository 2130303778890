import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { IconButton, TextField } from '@material-ui/core';
import { SearchOutlined } from '@material-ui/icons';
import theme from 'theme';

const searchTheme = createTheme({
  ...theme,
  overrides: {
    ...theme.overrides,
    MuiInputBase: {
      root: {
        maxWidth: '213px'
      },
      input: {
        fontSize: '15px',
        fontWeight: 500,
        color: '#0D1A3A',
        opacity: 1,
        '&::placeholder': {
          color: '#0D1A3A',
          opacity: 1
        }
      }
    },
    MuiIconButton: {
      root: {
        padding: '10px'
      }
    }
  }
});

/**
 * SearchBox component
 * @description For searching causal chains
 * @param {Object} props - The component props
 * @param {string} props.searchInputValue - The current search input value
 * @param {function} props.setSearchInputValue - The function to set the search input value
 * @param {string} props.sort - The current sort value
 * @param {array} props.terminatingEvents - The terminating events
 * @param {function} props.useSearchCausalChains - The function to search causal chains
 * @returns {JSX.Element} The SearchBox component
 */
function SearchBox(props) {
  const {
    searchInputValue,
    setSearchInputValue,
    sort,
    terminatingEvents,
    useSearchCausalChains
  } = props;

  useEffect(() => {
    if (searchInputValue.length > 0) {
      useSearchCausalChains({
        variables: {
          query: searchInputValue.trim(),
          sortField: sort,
          terminatingEvents
        },
        fetchPolicy: 'no-cache'
      });
    }
  }, [sort]);

  const searchCausalChains = e => {
    e.preventDefault();
    useSearchCausalChains({
      variables: {
        query: searchInputValue.trim(),
        sortField: sort,
        terminatingEvents
      },
      fetchPolicy: 'no-cache'
    });
  };

  const handleSearchInputChange = e => {
    setSearchInputValue(e.target.value);
  };

  return (
    <MuiThemeProvider theme={searchTheme}>
      <form onSubmit={searchCausalChains}>
        <TextField
          fullWidth
          id="standard-bare"
          variant="outlined"
          placeholder="Search"
          value={searchInputValue}
          onChange={handleSearchInputChange}
          InputProps={{
            endAdornment: (
              <IconButton onClick={searchCausalChains}>
                <SearchOutlined />
              </IconButton>
            )
          }}
        />
      </form>
    </MuiThemeProvider>
  );
}

SearchBox.propTypes = {
  searchInputValue: PropTypes.string.isRequired,
  setSearchInputValue: PropTypes.func.isRequired,
  sort: PropTypes.string.isRequired,
  terminatingEvents: PropTypes.arrayOf(PropTypes.string).isRequired,
  useSearchCausalChains: PropTypes.func.isRequired
};

export default SearchBox;
